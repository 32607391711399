<template>
    <Layout :auth='auth'>
        <Head title="Work Orders" />
        <template v-slot:title>
            Work Orders
            <template v-if="client">
                <span class="text-gray-500">&mdash;</span>
                <Link
                    :href="'/clients/' + client.id"
                    class="text-gray-300 hover:underline"
                >{{ client.name }}</Link>
            </template>
        </template>
        <template v-slot:actions>
            <hgselect v-model="pickClient" :options="options.clients" class="max-w-sm">
                <template v-slot:value-prefix>
                    <span class="font-medium">
                        Show work orders for:
                    </span>
                </template>
            </hgselect>
        </template>
        <div>
            <div class="mb-6">
                <div class="grid grid-cols-2">
                    <h2 class="font-medium text-lg ml-1 mb-3" v-if="showingAll">
                        All Work Orders
                    </h2>
                    <h2 class="font-medium text-lg ml-1 mb-3" v-else>
                        Active Work Orders
                    </h2>
                </div>

                <form @submit.prevent.stop='reloadSprints(1)' class='mb-4 flex items-end'>
                    <div class="flex-grow">
                        <label class="mb-1 block">Search By Name/Client/Payment:</label>
                        <input
                            autofocus
                            type="text"
                            v-model="query.query"
                            class="w-full"
                            style="padding-top: 6px; padding-bottom: 6px;"
                            placeholder="Search by work order name, client name, or payment number"
                        />
                    </div>
                    <div class="flex-grow mx-4">
                        <label class="mb-1 block">Status:</label>
                        <hgselect
                            v-model="query.status"
                            :options="{all: 'All', active: 'Active', finished: 'Finished', archive: 'Archive'}"
                            class="w-full"
                        />
                    </div>
                    <btn blue @click="reloadSprints(1)">
                        <i class="fal fa-search mr-2"></i>
                        Search
                    </btn>
                </form>

                <sprints-table
                    @reload='reloadSprints'
                    :sprints='sprints'
                    :clients='clients'
                />
                <div v-html='pagination' class="mt-4"></div>
            </div>
        </div>
    </Layout>
</template>


<script>
import btn from "../../Common/btn.vue"
import sprintsTable from "../../Common/sprintsTable.vue"
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import { Head, Link } from '@inertiajs/vue3'
import axios from "axios"
import { get } from "lodash"

export default {
    name: 'SprintsIndex',
    components: {
        btn,
        sprintsTable,
        Layout,
        hgselect,
        Link,
        Head
    },
    props: {
        auth: Object,
        client: Object,
        clients: Array,
        sprints: Array,
        pagination: String,
        initial: Object
    },
    data() {
        return {
            query: {
                query: get(this.initial, 'query', ''),
                status: get(this.initial, 'status', 'active')
            },
            showingAll: this.client ? true : false,
            markingAsBilled: false,
            pickClient: this.client ? this.client.id : ''
        }
    },
    computed: {
        options() {
            var opts = {
                clients: {
                    '': 'All Clients'
                },
            }
            this.clients.forEach(c => (opts.clients[c.id] = c.name))
            return opts
        }
    },
    methods: {
        reloadSprints(page) {
            this.$inertia.visit(location.pathname, {
                data: {
                    query: this.query.query ? this.query.query : null,
                    status: this.query.status,
                    page: page == 1 ? null : page
                }
            })
        },
        async markAsBilled() {
            if (!this.markingAsBilled) {
                this.markingAsBilled = true
                for(var idx = 0; idx < this.unbilledEntries.length; idx++) {
                    var entry = this.unbilledEntries[idx]
                    await axios.post(
                        this.$route("time_entries.update", entry.id),
                        { billed: true }
                    )
                }
                this.markingAsBilled = false
            }
        }
    },
    watch: {
        pickClient () {
            if (this.pickClient) {
                this.$inertia.visit('/sprints/client/' + this.pickClient)
            } else {
                this.$inertia.visit('/sprints')
            }
        }
    }
}
</script>

<style lang="postcss" scoped>

</style>
