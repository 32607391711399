  <template>
      <Layout :auth='auth'>
          <Head title="accessiBe Billings Report" />
          <template v-slot:title>accessiBe Billings Report</template>
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div
                  class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                          <tr>
                              <th @click='order("client")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
                                  Client
                                  <span v-show="orderBy[0] == 'client' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'client' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("payment")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
                                  Last Payment
                                  <span v-show="orderBy[0] == 'payment' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'payment' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("invoiced")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                  Invoiced
                                  <span v-show="orderBy[0] == 'invoiced' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'invoiced' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("overdue")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                  <span v-show="orderBy[0] == 'overdue' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'overdue' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("cost")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                  Actual
                                  <span v-show="orderBy[0] == 'cost' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'cost' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("hours")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                  <span v-show="orderBy[0] == 'hours' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'hours' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                              <th @click='order("profit")'
                                  class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                  Profit
                                  <span v-show="orderBy[0] == 'profit' && orderBy[1] == 'asc'"><i
                                          class="fas fa-caret-up"></i></span>
                                  <span v-show="orderBy[0] == 'profit' && orderBy[1] == 'desc'"><i
                                          class="fas fa-caret-down"></i></span>
                              </th>
                          </tr>
                      </thead>
                      <tbody class="bg-white">
                          <template v-for="stats in stats" :key="stats.client_id">
                              <tr :class="{ 'bg-gray-50': idx % 2 }">
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600">
                                      <Link :href='"/clients/" + stats.client_id' class="hover:underline">
                                          {{ clients[stats.client_id] }}
                                      </Link>
                                  </td>
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer">
                                      <span v-if="stats.lastPayment">{{ datetime(stats.lastPayment) }}</span><span
                                          v-else>Never</span>
                                  </td>
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer">
                                      {{ money(stats.paid + stats.unpaid) }}
                                  </td>
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer">
                                      <span class="text-red-500" v-if="stats.overdue">({{ money(stats.overdue) }}
                                          overdue)</span>
                                  </td>
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer">
                                      {{ money(stats.actual.cost) }}
                                  </td>
                                  <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer">
                                      {{ number(stats.actual.hours) }}hr
                                  </td>
                                  <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                    :class="{
                                        'text-red-600': (stats.paid + stats.unpaid - stats.actual.cost) < 0,
                                        'text-green-600': (stats.paid + stats.unpaid - stats.actual.cost) > 0
                                    }"
                                  >
                                      {{ money(stats.paid + stats.unpaid - stats.actual.cost) }}
                                  </td>
                              </tr>
                          </template>
                      </tbody>
                  </table>
              </div>
          </div>
      </Layout>
  </template>

<script>
import Layout from "../../Common/Layout.vue"
import moment from "moment"
import { Head, Link } from '@inertiajs/vue3'
import { orderBy } from "lodash"

export default {
    name: 'EversiteBillingsReport',
    components: {
        Layout, Link, Head
    },
    props: {
        auth: Object,
        sprintsByClient: Array,
        clients: Object,
    },
    data() {
        return {
          orderBy: ["client", "asc"],
        }
    },
    computed: {
      stats() {
          switch (this.orderBy[0]) {
              case 'client':
                return orderBy(this.sprintsByClient, (sprint) => {
                    return this.clients[sprint.client_id]
                }, this.orderBy[1]);
                case 'payment':
                    return orderBy(this.sprintsByClient, 'lastPayment', this.orderBy[1]);
                case 'invoiced':
                    return orderBy(this.sprintsByClient, (sprint) => {
                        return sprint.paid + sprint.unpaid;
                    }, this.orderBy[1]);
                case 'overdue':
                    return orderBy(this.sprintsByClient, 'overdue', this.orderBy[1]);
                case 'hours':
                    return orderBy(this.sprintsByClient, 'actual.hours', this.orderBy[1]);
                case 'cost':
                    return orderBy(this.sprintsByClient, 'actual.cost', this.orderBy[1]);
          }
          return this.sprintsByClient
      }
    },
    methods: {
      order(key) {
          if (this.orderBy[0] == key) {
              if (this.orderBy[1] == 'asc') {
                this.orderBy = [key, 'desc']
              }
          } else {
              this.orderBy = [key, 'asc']
          }
      },

      money (num) {
        if (Number(num) >= 0) {
            let value = Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `$${value}`
        } else {
            let value = Math.abs(Number(num)).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `-$${value}`
        }
      },
      number (num) {
        if (Number(num) >= 0) {
            let value = Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `${value}`
        } else {
            let value = Math.abs(Number(num)).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `-${value}`
        }
      },
      datetime(dt) {
          let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
          if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
              return date.format('MMM Do, h:mma')
          }
          return date.format('MMM Do YYYY, h:mma')
      },
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
</style>