<script setup>
import { Line } from "vue-chartjs";
import { colors } from './common.js';
import { startCase } from "lodash";

import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    },
    meta: {
        type: Object,
        default: () => ({
        })
    }
})

const chartData = {
    labels: [],
    datasets: {}
};

let colorCounter = 0;
Object.keys($props.data).forEach((label) => {
    chartData.labels.push(label);
    Object.keys($props.data[label]).forEach((key) => {
        if (!(key in chartData.datasets)) {
            const color = colors[colorCounter++];
            chartData.datasets[key] = {
                label: startCase(key),
                data: [],
                borderColor: color,
                backgroundColor: color,
                borderWidth: 2,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointHoverBackgroundColor: color,
                pointHoverBorderColor: color
            };
        }
        chartData.datasets[key].data.push($props.data[label][key]);
    });
});

chartData.datasets = Object.values(chartData.datasets);

let options = {
    scales: {
        y: {
            stacked: false,
            display: true
        },
        x: {
            display: true
        }
    },
    interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false
    },
    plugins: {
        legend: {
            display: true
        },
        tooltip: {
            mode: "index"
        }
    },
    responsive: true,
    maintainAspectRatio: false
};
const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  if (source) {
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], merge(key in target ? target[key] : {}, source[key]))
    }
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source || {})
  return target
}
options = merge(options, $props.meta.options)
</script>

<template>
    <section class="flex flex-col">
        <h1 class="text-base font-semibold leading-6 text-gray-900 mb-4">
            {{ title }}
        </h1>
        <div class="flow-root border shadow bg-white p-4 flex-grow">
          <Line :data="chartData" :options="options" />
        </div>
    </section>
</template>

<style>
canvas {
  height: 100% !important;
  max-width: 100% !important;
}
</style>
