<template>
    <div class="relative flex items-center">
        <span
            v-if="prefix"
            class="bg-white py-[8px] pl-3 text-sm rounded-l border border-gray-300 border-r-0 leading-5 select-none text-gray-500"
            v-text="prefix"
        ></span>
        <i v-if="icon" class="absolute z-10 text-gray-400 text-lg fal fa-magnifying-glass top-1 left-2" :class="icon"></i>
        <Mentionable v-if="variables" :keys="['@']" :items="variables" offset="6">
            <div class="grow-wrap w-full">
                <textarea
                    class="block w-full sm:text-sm sm:leading-5"
                    :class="{
                        'pl-8': icon
                    }"
                    :rows="multiLine ? numberOfNewLinesIn(val) : 1"
                    v-model="val"
                    :placeholder="placeholder"
                    @input="$event.target.parentNode.dataset.replicatedValue = val"
                ></textarea>
            </div>
        </Mentionable>
        <div
            v-else-if="multiLine"
            class="grow-wrap w-full"
        >
            <textarea
                class="block w-full"
                :class="{
                    'pl-8': icon,
                    ' sm:text-sm sm:leading-5': !unstyled
                }"
                v-model="val"
                :placeholder="placeholder"
                @input="$event.target.parentNode.dataset.replicatedValue = val"
            ></textarea>
        </div>
        <input
            v-else
            :type="type"
            class="block w-full"
            :class="{
                'pl-8': icon,
                '!border-l-0 !rounded-l-none pl-0': prefix,
                'sm:text-sm sm:leading-5': !unstyled
            }"
            v-model="val"
            :placeholder="placeholder"
        />
    </div>
</template>

<script>
    import 'floating-vue/dist/style.css'
    import { Mentionable } from 'vue-mention'
    export default {
        components: { Mentionable },
        props: {
            variables: {
                type: Array,
                default: null
            },
            type: {
                type: String,
                default: 'text'
            },
            modelValue: {
                type: [Number, String],
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            multiLine: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: ''
            },
            prefix: {
                type: String,
                default: ''
            },
            unstyled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                val: String(this.modelValue)
            }
        },
        methods: {
            mlInputChanged(ev) {
                ev.target.style.height = this.$refs.ghost.offsetHeight + 'px'
            },
            numberOfNewLinesIn(str) {
                if (str) {
                    let matches = str.match(/\n/g)
                    if (matches) {
                        return Math.min(matches.length + 1, 5)
                    }
                }
                return 1
            },
        },
        watch: {
            modelValue () {
                this.val = String(this.modelValue)
            },
            val () {
                this.$emit('update:modelValue', this.val)
            }
        }
    }
</script>

<style lang="postcss">
.mentionable {
    width: 100%;
}
.grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
}
.grow-wrap::after {
/* Note the weird space! Needed to preventy jumpy behavior */
content: attr(data-replicated-value) " ";

/* This is how textarea text behaves */
white-space: pre-wrap;

/* Hidden from view, clicks, and screen readers */
visibility: hidden;
}
.grow-wrap > textarea {
/* You could leave this, but after a user resizes, then it ruins the auto sizing */
resize: none;

/* Firefox shows scrollbar on growth, you can hide like this. */
overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
/* Identical styling required!! */
border: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
padding: 0.5rem;

/* Place on top of each other */
grid-area: 1 / 1 / 2 / 2;
}
</style>
