<template>
    <Layout :auth='auth'>
        <Head title="Settings" />
        <template v-slot:title>
            <i class="fal fa-utensils"></i>
            Settings
        </template>
        <template v-slot:actions>
        </template>
        <form action="#" method="POST" @submit.prevent="save">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Teams
                    </h3>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <nav class="space-y-1 max-w-md">
                        <div
                            v-for="team in teams"
                            :key="team.slug"
                            class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                            @click="editingTeam ? null : (editingTeam = {...team})"
                        >
                            <div
                                class="flex items-center"
                                v-if="editingTeam && editingTeam.id == team.id"
                            >
                                <input
                                    autofocus
                                    name="name"
                                    v-model="editingTeam.name"
                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                />
                                <span class="text-gray-400 text-sm">({{ team.slug }})</span>
                            </div>
                            <span class="truncate" v-else>
                                {{ team.name }}
                                <span class="text-gray-400 text-sm">({{ team.slug }})</span>
                            </span>
                            <div
                                v-if="editingTeam && editingTeam.id == team.id"
                                class="flex ml-auto"
                            >
                                <a
                                    @click.prevent.stop='editingTeam = null'
                                    href='#'
                                    class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600 mr-1">
                                    <i class="fal fa-times"></i>
                                </a>
                                <a
                                    @click.prevent.stop='saveTeam()'
                                    href='#'
                                    class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600 ml-1">
                                    <i class="fal fa-arrow-up"></i>
                                </a>
                            </div>
                            <a
                                v-else
                                @click.prevent.stop='removeTeam(team)'
                                href='#'
                                class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600">
                                <i class="fal fa-times"></i>
                            </a>
                        </div>
                        <div
                            class="border-t text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                        >
                            <div class="flex">
                                <input
                                    autofocus
                                    name="name"
                                    v-model="newTeam.name"
                                    @keyup.enter="createTeam"
                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                />
                            </div>
                            <a
                                @click.prevent.stop='createTeam()'
                                href='#'
                                class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600">
                                <i class="fal fa-arrow-up"></i>
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </form>
        <div class="mt-6 border-t pt-6 border-gray-200">
            <form action="#" method="POST" @submit.prevent="save">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Work Types
                        </h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            The type of work that people do around here.
                        </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <nav class="space-y-1 max-w-md">
                            <div
                                v-for="type in types"
                                :key="type.slug"
                                class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                                @click="editing ? null : (editing = {...type})"
                            >
                                <div
                                    class="flex"
                                    v-if="editing && editing.slug == type.slug"
                                >
                                    <input
                                        autofocus
                                        name="name"
                                        v-model="editing.name"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                    <money
                                        v-model="editing.rate"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                </div>
                                <span class="truncate" v-else>
                                    {{ type.name }}
                                    <span class="text-gray-400 text-sm">({{ type.slug }})</span>
                                    <span class="text-green-400 text-sm">${{ type.rate }}</span>
                                </span>
                                <div
                                    v-if="editing && editing.slug == type.slug"
                                    class="flex"
                                >
                                    <a
                                        @click.prevent.stop='editing = null'
                                        href='#'
                                        class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600 mr-1">
                                        <i class="fal fa-times"></i>
                                    </a>
                                    <a
                                        @click.prevent.stop='save()'
                                        href='#'
                                        class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600 ml-1">
                                        <i class="fal fa-arrow-up"></i>
                                    </a>
                                </div>
                                <a
                                    v-else
                                    @click.prevent.stop='removeType(type)'
                                    href='#'
                                    class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600">
                                    <i class="fal fa-times"></i>
                                </a>
                            </div>
                            <div
                                class="border-t text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                            >
                                <div class="flex">
                                    <input
                                        autofocus
                                        name="name"
                                        v-model="newType.name"
                                        @keyup.enter="createType"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                    <money
                                        v-model="newType.rate"
                                        @keyup.enter="createType"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                </div>
                                <a
                                    @click.prevent.stop='createType()'
                                    href='#'
                                    class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600">
                                    <i class="fal fa-arrow-up"></i>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </form>
        </div>
        <div class="mt-6 border-t pt-6 border-gray-200">
            <form action="#" method="POST" @submit.prevent="save">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Conversion Events
                        </h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            Better names for conversions in reports
                        </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <nav class="space-y-1 max-w-md">
                            <div
                                v-for="event in conversionEvents"
                                :key="'event-' + event.id"
                                class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                                @click="editing ? null : (editing = {...event})"
                            >
                                <div
                                    class="flex"
                                    v-if="editing && editing.id == event.id"
                                >
                                    <input
                                        autofocus
                                        name="name"
                                        v-model="editing.event_name"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                    <input
                                        v-model="editing.label"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                </div>
                                <span class="truncate" v-else>
                                    {{ event.label }}
                                    <span class="text-gray-400 text-sm">({{ event.event_name }})</span>
                                </span>
                                <div
                                    v-if="editing && editing.id == event.id"
                                    class="flex"
                                >
                                    <a
                                        @click.prevent.stop='editing = null'
                                        href='#'
                                        class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600 mr-1">
                                        <i class="fal fa-times"></i>
                                    </a>
                                    <a
                                        @click.prevent.stop='saveEvent()'
                                        href='#'
                                        class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600 ml-1">
                                        <i class="fal fa-arrow-up"></i>
                                    </a>
                                </div>
                                <a
                                    v-else
                                    @click.prevent.stop='removeEvent(event)'
                                    href='#'
                                    class="bg-red-100 group-hover:bg-red-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-red-600">
                                    <i class="fal fa-times"></i>
                                </a>
                            </div>
                            <div
                                class="border-t text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                            >
                                <div class="flex">
                                    <input
                                        name="event_name"
                                        v-model="newEvent.event_name"
                                        placeholder="event_name"
                                        @keyup.enter="createEvent"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                    <input
                                        name="label"
                                        v-model="newEvent.label"
                                        placeholder="Event Label"
                                        @keyup.enter="createEvent"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4"
                                    />
                                </div>
                                <a
                                    @click.prevent.stop='createEvent()'
                                    href='#'
                                    class="bg-green-100 group-hover:bg-green-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-green-600">
                                    <i class="fal fa-arrow-up"></i>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </form>
        </div>
        <div class="md:grid md:grid-cols-3 md:gap-6 mt-6 border-t pt-6 border-gray-200">
            <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Quickbooks Online
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    Manage Quickbooks online connection
                </p>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
                <template v-if="qbo == null">
                    Checking QBO Connection <i class="fal fa-spinner-third fa-spin"></i>
                </template>
                <template v-else-if="qbo.connected">
                    Connected to QBO as <span class="font-bold">{{ qbo.company }}</span>
                    &mdash;
                    <a
                        href="#"
                        @click.prevent="qboDisconnect"
                        class="text-blue-500"
                    >Disconnect</a>
                </template>
                <template v-else>
                    <btn
                        href="/qbo/connect"
                        blue
                    >Connect To QBO</btn>
                </template>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import money from "../../Common/money.vue"
import inp from "../../Common/inp.vue"
import btn from "../../Common/btn.vue"
import axios from "axios"
import { get } from "lodash"
import { Head } from '@inertiajs/vue3'

export default {
    name: "AdminSettings",
    props: {
        auth: Object,
        types: Array,
        teams: Array,
        holidays: String,
        conversionEvents: Array,
    },
    components: { btn, Head, Layout, money, inp },
    data() {
        return {
            qbo: null,
            editing: null,
            editingTeam: null,
            savingHolidays: false,
            holidayList: this.holidays,
            newTeam: {
                name: '',
                slug: '',
            },
            newType: {
                name: '',
                rate: 150
            },
            newEvent: {
                event_name: '',
                label: ''
            }
        }
    },
    mounted() {
        this.checkQBO()
    },
    methods: {
        async qboDisconnect() {
            this.qbo = null
            await axios.get(this.$route("qbo.disconnect"))
            this.checkQBO()
        },
        async checkQBO() {
            var response = await axios.get(this.$route("qbo.check"))
            this.qbo = response.data
        },
        async createEvent() {
            var response = await axios.post(
                this.$route("conversion_event_names.store"), {
                    event_name: this.newEvent.event_name,
                    label: this.newEvent.label,
                }
            )
            var message = get(response, "data.result")
            if (message) {
                alert(message);
            }
            this.newEvent.event_name = ''
            this.newEvent.label = 150
            await this.$inertia.reload({
                only: ['conversionEvents'],
            })
        },
        async createType() {
            var response = await axios.post(
                this.$route("work_types.store"), {
                    name: this.newType.name,
                    rate: this.newType.rate,
                }
            )
            var message = get(response, "data.result")
            if (message) {
                alert(message);
            }
            this.newType.name = ''
            this.newType.rate = 150
            await this.$inertia.reload({
                only: ['types'],
            })
        },
        async save() {
            if (this.editing) {
                await axios.post(
                    this.$route("work_types.update", {
                        work_type: this.editing.slug
                    }), {
                        _method: 'patch',
                        name: this.editing.name,
                        rate: this.editing.rate
                    }
                )
            }
            this.editing = null
            await this.$inertia.reload({
                only: ['types'],
            })
        },
        async removeType(type) {
            if (confirm("Are you sure you want to remove this type?")) {
                await axios.delete(
                    this.$route("work_types.destroy", {
                        work_type: type.slug
                    })
                )
                await this.$inertia.reload({
                    only: ['types'],
                })
            }
        },
        async removeEvent(eventName) {
            if (confirm("Are you sure you want to remove this event conversion name translation label thing?")) {
                await axios.delete(
                    this.$route("conversion_event_names.destroy", {
                        conversion_event_name: eventName.id
                    })
                )
                await this.$inertia.reload({
                    only: ['conversionEvents'],
                })
            }
        },
        async createTeam() {
            var response = await axios.post(
                this.$route("teams.store"), {
                    name: this.newTeam.name
                }
            )
            var message = get(response, "data.result")
            if (message) {
                alert(message);
            }
            this.newTeam.name = ''
            await this.$inertia.reload({
                only: ['teams'],
            })
        },
        async saveTeam() {
            if (this.editingTeam) {
                await axios.post(
                    this.$route("teams.update", {
                        team: this.editingTeam.id
                    }), {
                        _method: 'patch',
                        name: this.editingTeam.name,
                    }
                )
            }
            this.editingTeam = null
            await this.$inertia.reload({
                only: ['teams'],
            })
        },
        async removeTeam(team) {
            if (confirm("Are you sure you want to remove this team?")) {
                await axios.delete(
                    this.$route("teams.destroy", {
                        team: team.id
                    })
                )
                await this.$inertia.reload({
                    only: ['teams'],
                })
            }
        },
        async saveHolidayChanges() {
            this.savingHolidays = true
            await axios.post(
                    this.$route("settings.save", {
                        key: 'holidays'
                    }), {
                        data: this.holidayList
                    }
                )
            this.savingHolidays = false
        }
    },
}
</script>

<style lang="postcss" scoped></style>
