<template>
    <Layout :auth='auth'>
        <Head title="Payments" />
        <template v-slot:title>Payments</template>
        <template v-slot:actions v-if="hasPermission('manage:billing')">
            <hgselect v-model="pickClient" :options="options.clients" class="max-w-sm">
                <template v-slot:value-prefix>
                    <span class="font-medium">
                        Show payments from:
                    </span>
                </template>
            </hgselect>
            <btn
                white
                @click="createNewPayment = true"
                class="ml-3"
            >Create New</btn>
        </template>
        <div>
            <div class="mb-6">
                <div class="grid grid-cols-2">
                    <h2 class="font-medium text-lg ml-1 mb-3 flex items-center">
                        <span v-if="!onlyPaid && !onlyUnPaid">All Payments</span>
                        <span v-else-if="onlyPaid">Paid Payments</span>
                        <span v-else-if="onlyUnPaid">Unpaid Payments</span>
                        &nbsp;&mdash;&nbsp;
                        <ul class="inline-flex text-sm space-x-3">
                            <li v-if="onlyPaid || onlyUnPaid">
                                <Link
                                    :href="'/payments' + suffix"
                                    class="text-blue-800">Show All</Link>
                            </li>
                            <li v-if="!onlyPaid">
                                <Link
                                    :href="'/payments' + suffix + '?paid'"
                                    class="text-blue-800"
                                >Only Paid</Link>
                            </li>
                            <li v-if="!onlyUnPaid">
                                <Link
                                    :href="'/payments' + suffix + '?unpaid'"
                                    class="text-blue-800"
                                >Only Unpaid</Link>
                            </li>
                        </ul>
                    </h2>
                </div>
                <payments-table
                    :auth='auth'
                    :payments='payments'
                    :editable="true"
                    :local-ordering="false"
                    :order-by="orderByAsArray"
                    @order="orderChanged"
                    @refresh='refresh'
                    @sprintClicked='gotoSprint'
                    @paymentClicked='gotoPayment'
                    @clientClicked='(id) => pickClient = id'
                />
                <!-- flex items-center justify-between -->
                <!-- flex justify-between flex-1 sm:hidden -->
                <!-- hidden sm:flex-1 sm:flex sm:items-center sm:justify-between -->
                <div v-html='pagination' class="mt-4"></div>
            </div>
        </div>
        <modal :show='createNewPayment'>
            <payment-form
                v-if="createNewPayment"
                :payment="null"
                :for-client="pickClient"
                @saved='refresh'
                @cancel='createNewPayment = false'
            />
        </modal>
    </Layout>
</template>


<script>
import modal from "../../Common/modal.vue"
import btn from "../../Common/btn.vue"
import paymentForm from "../../Common/paymentForm.vue"
import paymentsTable from "../../Common/paymentsTable.vue"
import Layout from "../../Common/Layout.vue"
import { Head, Link } from '@inertiajs/vue3'
import hgselect from "../../Common/hgselect.vue"
import auth from "../../Common/Mixins/auth"
// import { pickBy } from "lodash"

export default {
    name: 'PaymentIndex',
    mixins: [auth],
    components: {
        btn,
        Head,
        hgselect,
        paymentForm,
        paymentsTable,
        Layout,
        modal,
        Link
    },
    props: {
        onlyPaid: Boolean,
        onlyUnPaid: Boolean,
        order: String,
        auth: Object,
        client: Object,
        clients: Array,
        payments: Array,
        pagination: String,
        currentUrl: String,
        suffix: String
    },
    data() {
        return {
            createNewPayment: false,
            showingAll: false,
            pickClient: this.client ? this.client.id : '',
        }
    },
    computed: {
        options() {
            var opts = {
                clients: {
                    '': 'All Clients'
                },
            }
            this.clients.forEach(c => (opts.clients[c.id] = c.name))
            return opts
        },
        orderByAsArray() {
            if (this.order) {
                return [
                    this.order[0] === '-' ? this.order.substr(1) : this.order,
                    this.order[0] === '-' ? 'desc' : 'asc'
                ];
            }
            return [null, null]
        }
    },
    methods: {
        gotoSprint(sprint_id) {
            this.$inertia.visit('/sprints/' + sprint_id)
        },
        gotoPayment(payment) {
            this.$inertia.visit('/payments/' + payment.id)
        },
        orderChanged(order) {
            let orderBy = null
            if (order && order[0]) {
                orderBy = order[1] === 'desc' ? `-${order[0]}` : order[0];
            }
            if (this.currentUrl) {
                this.$inertia.visit(this.currentUrl, { data: { order: orderBy } })
            } else {
                this.$inertia.visit('/payments', { data: { order: orderBy } })
            }
        },
        refresh() {
            this.createNewPayment = false
            this.$inertia.reload()
        }
    },
    watch: {
        pickClient () {
            if (this.pickClient) {
                this.$inertia.visit('/payments/client/' + this.pickClient)
            } else {
                this.$inertia.visit('/payments')
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
