<template>
    <Layout :auth='auth'>
        <Head :title="client.name" />
        <template v-slot:title>{{ client.name }}</template>
        <template v-slot:actions>
            <btn class="mr-4" white outline @click="startMerge = true">
                <i class="fa fa-spinner-third fa-spin" v-if="merging"></i>
                Merge Client
            </btn>
            <btn class="mr-4" red @click="archive">
                <i class="fa fa-spinner-third fa-spin" v-if="archiving"></i>
                Archive Client
            </btn>
            <btn white :href="$route('clients.edit', client.id)">
                Edit Client
            </btn>
        </template>

        <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                <div class="flex items-center flex-wrap">
                    <span class="flex-grow">Client Information</span>
                    <img
                        v-if="client.logo"
                        :src="$url(client.logo)"
                        class="h-8 object-cover"
                    />
                </div>
            </h3>
        </div>
        <div class="px-4 py-5 sm:p-0">
            <dl>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    v-if="client.name"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Name
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ client.name }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.status"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Status
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ translateStatus(client.status) }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.tags && client.tags.length"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Tags
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ client.tags.join(", ") }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.website"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Website
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ client.website }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.launch_date"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Launch Date
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ dateFormat(client.launch_date) }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.domains && client.domains.length"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Domains
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ client.domains.join(", ") }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.description"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Description
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ client.description }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.contact_name || client.contact_email || client.contact_phone"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Client Contact
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        <div v-if="client.contact_name">
                            <i class="fa fa-user text-gray-400"></i>
                            <span class="ml-2 flex-1 w-0 truncate">
                                {{ client.contact_name }}
                            </span>
                        </div>
                        <div v-if="client.contact_name">
                            <i class="fa fa-envelope text-gray-400"></i>
                            <span class="ml-2 flex-1 w-0 truncate">
                                {{ client.contact_email }}
                            </span>
                        </div>
                        <div v-if="client.contact_phone">
                            <i class="fa fa-phone text-gray-400"></i>
                            <span class="ml-2 flex-1 w-0 truncate">
                                {{ client.contact_phone }}
                            </span>
                        </div>
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="client.rates"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Client Rates
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        <table>
                            <tr v-for='(rate, key) in client.rates' :key='"rate-" + key'>
                                <td class="font-medium pr-2">{{ type(key, 'name', key) }}</td>
                                <td>${{ client.rates[key] }}</td>
                            </tr>
                        </table>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="flex flex-col gap-5 ml-5">
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select
                        v-model="activeTab"
                        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                        <option value="activity">Activity</option>
                        <option value="work-orders">Work Orders</option>
                        <option value="payments">Payments</option>
                        <option value="time-entries">Time Entries</option>
                        <option value="users">User Accounts</option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <a
                                href="#"
                                @click.prevent="activeTab = 'activity'"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'activity',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'activity',
                                }"
                            >Activity</a>
                            <a
                                href="#"
                                @click.prevent="activeTab = 'sprints'"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'sprints',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'sprints',
                                }"
                            >Work Orders</a>
                            <a
                                href="#"
                                @click.prevent="activeTab = 'payments'"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'payments',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'payments',
                                }"
                            >Payments</a>
                            <a
                                href="#"
                                @click.prevent="activeTab = 'time-entries'"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'time-entries',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'time-entries',
                                }"
                            >Time Entries</a>
                            <a
                                href="#"
                                @click.prevent="activeTab = 'users'"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'users',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'users',
                                }"
                            >Users</a>
                            <a
                                v-if="client.ga4_property_id || client.sem_rush_project_id"
                                :href="'/analytics/' + client.id"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'analytics',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'analytics',
                                }"
                            >Site Analytics</a>
                            <a
                                v-if="client.ga4_property_id"
                                :href="'/case-study/' + client.id"
                                class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': activeTab == 'analytics',
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': activeTab !== 'analytics',
                                }"
                            >Case Study</a>
                        </nav>
                    </div>
                </div>
            </div>

            <template v-if="activeTab == 'activity'">
                <div class="py-5 sm:p-0">
                    <section v-if="memos && memos.length">
                        <div class="flex flex-col divide-y divide-gray-200 border-b border-gray-200" v-if="memos && memos.length">
                            <MemoRow
                                v-for='memo in memos'
                                :key="'memo-' + memo.id"
                                :memo="memo"
                                :usersById="usersById"
                                :teamsById="teamsById"
                                no-links
                            />
                        </div>
                        <div class="mt-4 mr-2 text-right">
                            <Link
                                :href="`/activity?client=${client.id}`"
                                class="text-sm text-blue-600 hover:text-blue-800"
                            >
                                View All Feedback
                            </Link>
                        </div>
                    </section>
                    <div v-else-if="memos === null">
                        Loading... <i class="fa fa-spinner-third fa-spin"></i>
                    </div>
                    <div v-else class="py-2">
                        <alert color="green">
                            This client doesn't have any Activity yet.
                        </alert>
                    </div>
                </div>
            </template>
            <template v-if="activeTab == 'users'">
                <div class="px-4 py-5 sm:p-0">
                    <div class="flex flex-col" v-if="users && users.length">
                        <users-table
                            :client='client'
                            :users='users'
                            editable
                        />
                    </div>
                    <div v-else-if="users === null">
                        Loading... <i class="fa fa-spinner-third fa-spin"></i>
                    </div>
                    <div v-else class="p-2">
                        <alert color="green">
                            This client doesn't have any users yet.
                        </alert>
                    </div>
                    <div class="mt-4 mr-2 text-right">
                        <a
                            href="#"
                            class="text-sm text-blue-600 hover:text-blue-800"
                            @click.prevent="startAddingNewUser()"
                        >
                            Add New User
                        </a>
                    </div>
                </div>
            </template>

            <template v-if="activeTab == 'sprints'">
                <div>
                    <div class="flex flex-col" v-if="sprints && sprints.length">
                        <sprints-table
                            :show-client="false"
                            :sprints="sprints"
                            @saved='sprintUpdated'
                        />
                    </div>
                    <div v-else-if="sprints === null">
                        Loading... <i class="fa fa-spinner-third fa-spin"></i>
                    </div>
                    <alert v-else color="green">
                        This client hasn't had any work orders yet.
                    </alert>
                </div>
            </template>

            <template v-if="activeTab == 'payments'">
                <payments-table
                    :auth='auth'
                    :payments='payments'
                    :editable="true"
                    :local-ordering="false"
                    :show-client="false"
                />
            </template>

            <template v-if="activeTab == 'time-entries'">
                <div class="">
                    <div class="flex flex-col" v-if="timeEntries && timeEntries.length">
                        <entries-table
                            show-week
                            show-sprint
                            show-type
                            :types="typeOptions"
                            :entries='timeEntries'
                        />
                    </div>
                    <div v-else-if="timeEntries === null">
                        Loading... <i class="fa fa-spinner-third fa-spin"></i>
                    </div>
                    <alert v-else color="green">
                        This client hasn't had any time logged yet.
                    </alert>
                </div>
            </template>
        </div>

        <modal :show="startMerge" @close='startMerge = false' overflow-visible>
            <p class="mb-2">Merge this client into another one. All of this clients time entries will be copied over to the other one.</p>
            <p class="italic mb-4">The other client will retain all of it's details and contact information, and this one's will be lost.</p>
            <div>
                <label for="client" class="block text-sm leading-5 font-medium text-gray-700">Merge Into Client</label>
                <hgselect
                    id="client"
                    v-model="clientToMergeWith"
                    class="mt-1"
                    :options="otherClients"
                ></hgselect>
                <btn class="mt-4" red @click="doMerge" :disabled="!clientToMergeWith">
                    <i class="fa fa-spinner-third fa-spin" v-if="archiving"></i>
                    Merge Client
                </btn>
            </div>
        </modal>

        <modal :show="!!newUser" @close="newUser = false">
            <userForm
                v-if="newUser"
                v-model="newUser"
                @saved="userSaved()"
                @cancel="newUser = false"
            />
        </modal>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import alert from "../../Common/alert.vue"
import btn from "../../Common/btn.vue"
import sprintsTable from "../../Common/sprintsTable.vue"
import paymentsTable from "../../Common/paymentsTable.vue"
import entriesTable from "../../Common/entriesTable.vue"
import usersTable from "../../Common/usersTable.vue"
import userForm from "../../Common/userForm.vue"
import hgselect from "../../Common/hgselect.vue"
import modal from "../../Common/modal.vue"
import MemoRow from "../Activity/Common/memoRow.vue"
import axios from "axios"
import { clone, find, get } from "lodash"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"

export default {
    components: {
        Layout, btn, alert, modal, hgselect, entriesTable,
        sprintsTable, usersTable, userForm, paymentsTable,
        MemoRow, Link, Head
    },
    props: {
        auth: Object,
        client: {
            type: Object,
            default: null
        },
        users: Array,
        clients: Object,
        types: Array,
        usersById: Object,
        teamsById: Object
    },
    data() {
        return {
            showAllWorkOrders: true,
            newUser: false,
            archiving: false,
            merging: false,
            startMerge: false,
            clientToMergeWith: null,
            activeTab: 'activity',
            memos: null,
            payments: null,
            sprints: null,
            timeEntries: null
        }
    },
    mounted() {
        this.activeTabChanged();
    },
    computed: {
        activeSprints() {
            return this.sprints.filter((sprint) => {
                return sprint.status == 'active'
            })
        },
        typeOptions() {
            const types = {}
            this.types.forEach((type) => {
                types[type.slug] = type.name
            })
            return types
        },
        otherClients() {
            const clients = clone(this.clients)
            delete clients[this.client.id];
            return clients
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(date.split('T')[0]).format("MMM D, YYYY")
            }
            return '--'
        },
        activeTabChanged() {
            switch(this.activeTab) {
                case 'activity':
                    axios.get(this.$route('clients.memos', this.client.id))
                        .then((response) => {
                            this.memos = response.data
                        });
                    break;
                case 'payments':
                    axios.get(this.$route('clients.payments', this.client.id))
                        .then((response) => {
                            this.payments = response.data
                        });
                    break;
                case 'sprints':
                    axios.get(this.$route('clients.sprints', this.client.id))
                        .then((response) => {
                            this.sprints = response.data
                        });
                    break;
                case 'time-entries':
                    axios.get(this.$route('clients.timeEntries', this.client.id))
                        .then((response) => {
                            this.timeEntries = response.data
                        });
                    break;
            }
        },
        translateStatus(status) {
            const statuses = {
                active: 'Active',
                canceled: 'Canceled',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            }
            return statuses[status] || status
        },
        type(slug, key, def) {
            const type = find(this.types, { slug })
            return get(type, key, def)
        },
        sprintUpdated () {
            this.$inertia.reload({
                only: ['sprints']
            })
        },
        async doMerge () {
            if (confirm("Are you sure you want to merge these clients?")) {
                this.merging = true
                await axios.get(
                    this.$route("clients.merge", {
                        from: this.client.id,
                        into: this.clientToMergeWith
                    })
                )
                this.$inertia.visit(this.$route("clients.show", this.clientToMergeWith))
                this.merging = false
            }
        },
        async archive() {
            if (confirm("Are you sure you want to archive this client?")) {
                this.archiving = true
                await this.$inertia.delete(
                    this.$route("clients.destroy", this.client.id)
                )
                this.archiving = false
            }
        },
        userSaved() {
            this.newUser = false
            this.$inertia.reload({
                only: ['users']
            })
        },
        startAddingNewUser() {
            this.newUser = {
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                client_id: this.client.id,
                is_active: true,
                type: 'client',
                permissions: ['activity']
            }
        }
    },
    watch: {
        activeTab() {
            this.activeTabChanged();
        }
    }
}
</script>

<style lang="postcss" scoped></style>
