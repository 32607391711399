import Echo from "laravel-echo"
import { createInertiaApp } from "@inertiajs/vue3";
import Vapor from 'laravel-vapor'
import { createApp, h } from 'vue'
import Cookies from 'js-cookie'
import VueClipboard from 'vue-clipboard2'
import axios from "axios"
import { flare } from "@flareapp/flare-client";
import { router } from '@inertiajs/vue3'

// only launch in production, we don't want to waste your quota while you're developing.
if (process.env.NODE_ENV === 'production') {
    flare.light(window.hourglass.flare.key);
}
import { flareVue } from "@flareapp/flare-vue";
import '../css/app.css';

VueClipboard.config.autoSetContainer = true

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: window.hourglass.pusher.key,
    cluster: window.hourglass.pusher.cluster,
    wsHost: window.hourglass.pusher.host,
    wsPort: window.hourglass.pusher.port,
    scheme: window.hourglass.pusher.scheme,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

window.Echo.connector.pusher.connection.bind('connected', function () {
    axios.defaults.headers.common['X-Socket-ID'] = 'application/json';
});

window.axios = axios;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

Vapor.withBaseAssetUrl(import.meta.env.ASSET_URL)
window.Vapor = Vapor

router.on('before', () => {
    const newUrl = event.detail.visit.url.pathname + event.detail.visit.url.search
    const currentUrl = window.location.pathname + window.location.search
    if (newUrl !== currentUrl) {
        Cookies.set('previous-url', currentUrl, { expires: 1 })
    }
})

createInertiaApp({
    title: title => `${title} - Eversite Relay`,
    resolve: name => {
      const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
      return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props)
        })
        app.mixin({
            methods: {
                asset: function (path) {
                    return window.asset_url + path
                },
            },
        })
        app.config.globalProperties.$route = (...args) => window.route(...args).url()
        app.config.globalProperties.$current = path => window.route().current(path)
        app.config.globalProperties.$url = path => `/${path}`

        app.use(VueClipboard)
        app.use(flareVue)
        app.use(plugin)
            .mount(el)
    },
})