<template>
    <Layout :auth="auth">
        <Head title="Eversite Billings Report" />
        <template v-slot:title>Eversite Billings Report</template>
        <div class="flex items-center gap-4 mb-2">
            <div class="flex-grow aspect-[5] lg:aspect-[10]">
                <LineChart :data="totalChart.data" :options="totalChart.options" />
            </div>
            <div class="grid grid-cols-12 gap-2 text-center">
                <div class="col-span-3" :class="statusColor('active')">
                    <label class="font-medium opacity-75 uppercase text-sm">Active</label>
                    <div class="text-3xl">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'active' : false).length }}</div>
                </div>
                <div class="col-span-3" :class="statusColor('free')">
                    <label class="font-medium opacity-75 uppercase text-sm">Free</label>
                    <div class="text-3xl">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'free' : false).length }}</div>
                </div>
                <div class="col-span-3" :class="statusColor('canceled')">
                    <label class="font-medium opacity-75 uppercase text-sm">Canceled</label>
                    <div class="text-3xl">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'canceled' : false).length }}</div>
                </div>
                <div class="col-span-3" :class="statusColor('lost')">
                    <label class="font-medium opacity-75 uppercase text-sm">Lost</label>
                    <div class="text-3xl">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'lost' : false).length }}</div>
                </div>
                <div class="col-span-4 flex gap-2 justify-center items-center">
                    <label class="font-medium opacity-75 uppercase text-sm">Bronze:</label>
                    <div class="text-2xl font-black text-[#CD7F32]">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'active' : false).filter(s => s.level == 'Bronze').length }}</div>
                </div>
                <div class="col-span-4 flex gap-2 justify-center items-center">
                    <label class="font-medium opacity-75 uppercase text-sm">Silver:</label>
                    <div class="text-2xl font-black text-[#C0C0C0]">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'active' : false).filter(s => s.level == 'Silver').length }}</div>
                </div>
                <div class="col-span-4 flex gap-2 justify-center items-center">
                    <label class="font-medium opacity-75 uppercase text-sm">Gold:</label>
                    <div class="text-2xl font-black text-[#FFD700]">{{ stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'active' : false).filter(s => s.level == 'Gold').length }}</div>
                </div>
                <div class="col-span-6">
                    <label class="font-medium opacity-75 uppercase text-sm">Retention Rate</label>
                    <div class="text-3xl">
                        {{
                            Math.round(
                                stats.filter(s => s.client_id in clients ? clients[s.client_id].status == 'active' : false).length
                                / stats.filter(s => s.client_id in clients ? clients[s.client_id].status !== 'lost' : false).length
                                * 100
                            )
                        }}%
                    </div>
                </div>
                <div class="col-span-6">
                    <label class="font-medium opacity-75 uppercase text-sm">Avg Launch Time</label>
                    <div class="text-3xl">
                        {{ averageLaunchTime }} days
                    </div>
                </div>
            </div>
        </div>
        <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
            >
                <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="px-1"></th>
                            <th
                                @click="order('client')"
                                class="whitespace-nowrap cursor-pointer pr-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                <div>
                                    Client
                                    <span
                                        v-show="
                                            orderBy[0] == 'client'
                                            && orderBy[1] == 'asc'
                                        "
                                        ><i class="fas fa-caret-up"></i
                                    ></span>
                                    <span
                                        v-show="
                                            orderBy[0] == 'client'
                                            && orderBy[1] == 'desc'
                                        "
                                        ><i class="fas fa-caret-down"></i
                                    ></span>
                                </div>
                                <input
                                    @click.stop
                                    type="text"
                                    class="w-full mt-2 px-2 py-1 text-xs bg-white/40"
                                    v-model="query.client"
                                    placeholder="Showing all clients..."
                                />
                            </th>

                            <th
                                @click="order('status')"
                                class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                <div>
                                    Status
                                    <span
                                        v-show="
                                            orderBy[0] == 'status'
                                            && orderBy[1] == 'asc'
                                        "
                                        ><i class="fas fa-caret-up"></i
                                    ></span>
                                    <span
                                        v-show="
                                            orderBy[0] == 'status' &&
                                                orderBy[1] == 'desc'
                                        "
                                        ><i class="fas fa-caret-down"></i
                                    ></span>
                                </div>
                                <select
                                    @click.stop
                                    class="w-full mt-2 px-2 py-1 text-xs bg-white/40"
                                    v-model="query.status"
                                >
                                    <option value="">All</option>
                                    <option value="active">Active</option>
                                    <option value="canceled">Canceled</option>
                                    <option value="paused">Paused</option>
                                    <option value="lost">Lost</option>
                                    <option value="free">Free</option>
                                </select>
                            </th>
                            <th
                                @click="order('level')"
                                class="whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                <div>
                                   Level
                                    <span
                                        v-show="
                                            orderBy[0] == 'level'
                                            && orderBy[1] == 'asc'
                                        "
                                        ><i class="fas fa-caret-up"></i
                                    ></span>
                                    <span
                                        v-show="
                                            orderBy[0] == 'level' &&
                                                orderBy[1] == 'desc'
                                        "
                                        ><i class="fas fa-caret-down"></i
                                    ></span>
                                </div>
                                <select
                                    @click.stop
                                    class="w-full mt-2 px-2 py-1 text-xs bg-white/40"
                                    v-model="query.level"
                                >
                                    <option value="">All</option>
                                    <option value="Bronze">Bronze</option>
                                    <option value="Silver">Silver</option>
                                    <option value="Gold">Gold</option>
                                </select>
                            </th>
                            <th
                                @click="order('payments.first')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                First<br>Invoice
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.first' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.first' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('payments.last')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-center font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Most Recent<br>Invoice
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.last' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.last' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('weeks')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Weeks of<br>Service
                                <span
                                    v-show="
                                        orderBy[0] == 'weeks' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'weeks' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('launch_date')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Launch<br>Date
                                <span
                                    v-show="
                                        orderBy[0] == 'launch_date' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'launch_date' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('ttl')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Time To<br>Launch
                                <span
                                    v-show="
                                        orderBy[0] == 'ttl' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'ttl' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                v-if="hasPermission('manage:billing')"
                                @click="order('invoiced')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Invoiced
                                <span
                                    v-show="
                                        orderBy[0] == 'invoiced' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'invoiced' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('overdue')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Overdue
                                <span
                                    v-show="
                                        orderBy[0] == 'overdue' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'overdue' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('hours')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Hours
                                <span
                                    v-show="
                                        orderBy[0] == 'hours' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'hours' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                v-if="hasPermission('manage:billing')"
                                @click="order('rate')"
                                class="align-top whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Rate
                                <span
                                    v-show="
                                        orderBy[0] == 'rate' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'rate' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <template
                            v-for="(stats, idx) in filteredStats"
                            :key="stats.client_id"
                        >
                            <tr
                                :class="{
                                    'odd:bg-gray-50': !(timeToLaunch(stats) == 0 && timeSinceFirstPayment(stats) > 80),
                                    'bg-blue-100 border-b border-blue-200': timeToLaunch(stats) == 0 && timeSinceFirstPayment(stats) > 80,
                                }"
                            >
                                <td class="px-1 py-3 flex flex-col items-center justify-center gap-2">
                                    <span
                                        v-if="stats.has_marketing.seo"
                                        class="text-xs py-px px-2 rounded-full"
                                        :class="{
                                            'bg-blue-50 text-blue-500': !olderThan(stats.has_marketing.seo.due_date, 60) && stats.has_marketing.seo.name.indexOf('Setup') >= 0,
                                            'bg-green-50 text-green-500': !olderThan(stats.has_marketing.seo.due_date, 60) && stats.has_marketing.seo.name.indexOf('Setup') == -1,
                                            'bg-red-50 text-red-500': olderThan(stats.has_marketing.seo.due_date, 60)
                                        }"
                                    >SEO</span>
                                    <span
                                        v-if="stats.has_marketing.ppc"
                                        class="text-xs py-px px-2 rounded-full"
                                        :class="{
                                            'bg-blue-50 text-blue-500': !olderThan(stats.has_marketing.ppc.due_date, 60) && stats.has_marketing.ppc.name.indexOf('Setup') >= 0,
                                            'bg-green-50 text-green-500': !olderThan(stats.has_marketing.ppc.due_date, 60) && stats.has_marketing.ppc.name.indexOf('Setup') == -1,
                                            'bg-red-50 text-red-500': olderThan(stats.has_marketing.ppc.due_date, 60)
                                        }"
                                    >PPC</span>
                                </td>
                                <td
                                    class="pr-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
                                >
                                    <div class="flex flex-col">
                                        <div class="flex gap-2 items-start">
                                            <Link
                                                :href="'/clients/' + stats.client_id"
                                                class="hover:underline"
                                            >
                                                {{
                                                    stats.client_id in clients
                                                        ? clients[stats.client_id].name
                                                        : stats.client_id
                                                }}
                                            </Link>
                                        </div>
                                        <a
                                            v-if="
                                                stats.client_id in clients
                                                    ? clients[stats.client_id]
                                                        .website
                                                    : false
                                            "
                                            :href="clients[stats.client_id].website.includes('http') ? clients[stats.client_id].website : 'http://' + clients[stats.client_id].website"
                                            target="_blank"
                                            class="text-xs text-blue-800 hover:underline block mt-1 "
                                        >
                                            {{ clients[stats.client_id].website }}
                                        </a>
                                    </div>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span
                                        v-if="
                                            stats.client_id in clients
                                                ? clients[stats.client_id]
                                                      .status
                                                : false
                                        "
                                        :class="statusColor(
                                            clients[stats.client_id].status
                                        )"
                                    >
                                        {{ translateStatus(clients[stats.client_id].status) }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    {{ stats.level }}
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-center text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="stats.firstPayment">{{
                                        date(stats.firstPayment)
                                    }}</span
                                    ><span v-else>Never</span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-center text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="stats.lastPayment">{{
                                        date(stats.lastPayment)
                                    }}</span
                                    ><span v-else>Never</span>
                                </td>
                                <td
                                    class="px-6 py-3 text-center whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="stats.firstPayment && stats.lastPayment">
                                        {{ numberOfWeeks(stats) }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span v-if="stats.client_id in clients && clients[stats.client_id].launch_date">
                                        {{ date(clients[stats.client_id].launch_date) }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span v-if="stats.firstPayment && stats.client_id in clients && clients[stats.client_id].launch_date">
                                        {{ timeToLaunch(stats) }} days
                                    </span>
                                </td>
                                <td
                                    v-if="hasPermission('manage:billing')"
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    {{ money(stats.paid + stats.unpaid) }}
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span
                                        class="text-red-500"
                                        v-if="stats.overdue"
                                        >{{ money(stats.overdue) }}</span
                                    >
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    {{ number(stats.actual.hours) }}hr
                                </td>
                                <td
                                    v-if="hasPermission('manage:billing')"
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                    :class="{
                                        'text-gray-600':
                                            stats.actual.hours == 0,
                                        'text-red-600':
                                            stats.actual.hours > 0 &&
                                            (stats.paid + stats.unpaid) /
                                                stats.actual.hours <
                                                50,
                                        'text-green-600':
                                            stats.actual.hours > 0 &&
                                            (stats.paid + stats.unpaid) /
                                                stats.actual.hours >=
                                                50
                                    }"
                                >
                                    <template v-if="stats.actual.hours > 0">
                                        {{
                                            money(
                                                (
                                                    (stats.paid +
                                                        stats.unpaid) /
                                                    stats.actual.hours
                                                ).toFixed(2)
                                            )
                                        }}/hr
                                    </template>
                                    <template v-else> -- </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue";
import LineChart from "../../Charts/Line.vue"
import { Head, Link } from "@inertiajs/vue3";
import auth from "../../Common/Mixins/auth"
import moment from "moment";
import { orderBy } from "lodash";

export default {
    name: "EversiteBillingsReport",
    components: {
        Layout,
        Head,
        Link,
        LineChart
    },
    mixins: [ auth ],
    props: {
        auth: Object,
        sprintsByClient: Array,
        clients: Object
    },
    data() {
        return {
            orderBy: ["client", "asc"],
            query: {
                client: '',
                status: '',
                level: ''
            }
        };
    },
    computed: {
        filteredStats() {
            return this.stats.filter(s => {
                if (this.query.client && s.client_id in this.clients) {
                    if (!this.clients[s.client_id].name.toLowerCase().includes(this.query.client.toLowerCase())) {
                        return false
                    }
                }
                if (this.query.status && s.client_id in this.clients) {
                    if (this.clients[s.client_id].status != this.query.status) {
                        return false
                    }
                }
                if (this.query.level) {
                    if (s.level != this.query.level) {
                        return false
                    }
                }
                return true
            })
        },
        averageLaunchTime() {
            const launchTimes = this.stats.map(s => this.timeToLaunch(s)).filter(t => t > 0)
            if (launchTimes.length == 0) {
                return 0
            }
            return Math.round(launchTimes.reduce((a, b) => a + b) / launchTimes.length)
        },
        totalChart() {
            // Do a weekly chart, summing through each client and summing the number of active clients, as
            // determined by whether the date is between the first and last payment dates.
            let chart = {};
            let now = moment.utc();
            let startDate = moment.utc().startOf("week");
            let endDate = moment.utc().startOf("week");
            for (let sprint of this.sprintsByClient) {
                let first = moment.utc(sprint.firstPayment, "YYYY-MM-DD HH:mm:ss");
                let start = moment.utc(first).startOf("week");
                if (start.isBefore(startDate)) {
                    startDate = start;
                }
            }

            while(startDate.isBefore(endDate) || startDate.isSame(endDate)) {
                chart[startDate.format("YYYY-MM-DD")] = 0;
                startDate.add(1, "week");
            }

            for (let sprint of this.sprintsByClient) {
                let client = this.clients[sprint.client_id];
                if (!client) {
                    continue;
                }
                let first = moment.utc(sprint.firstPayment, "YYYY-MM-DD HH:mm:ss");
                let last = moment.utc(sprint.lastPayment, "YYYY-MM-DD HH:mm:ss").add(1, "month");
                let start = moment.utc(first).startOf("week");
                let end = moment.utc(last).endOf("week");
                while (start.isBefore(end)) {
                    if (start.format("YYYY-MM-DD") in chart) {
                        if (start.isBetween(first, last) && start.isBefore(now)) {
                            chart[start.format("YYYY-MM-DD")]++;
                        }
                    }
                    start.add(1, "week");
                }
            }

            const labels = Object.keys(chart).sort();
            const data = {
                labels,
                datasets: [{
                    label: "Change",
                    borderColor: '#000000',
                    pointStyle: false,
                    data: labels.map((label, idx) => {
                        if (idx == 0) return 0
                        return chart[label] - chart[labels[idx - 1]]
                    })
                }, {
                    label: "Number of Eversites",
                    borderColor: '#55b98a',
                    backgroundColor: '#55b98a',
                    pointStyle: false,
                    fill: 'origin',
                    data: labels.map((label) => chart[label])
                }, ]
            }

            return {
                data,
                options: {
                    scales: {
                        y: {
                            stacked: true,
                            display: false
                        },
                        x: {
                            display: false,
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        axis: 'x',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: 'index'
                        }
                    },
                    responsive:true,
                    maintainAspectRatio: false
                }
            }
        },
        stats() {
            switch (this.orderBy[0]) {
                case "client":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return sprint.client_id in this.clients ? this.clients[sprint.client_id].name.toLowerCase() : '--';
                        },
                        this.orderBy[1]
                    );
                case "status":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return sprint.client_id in this.clients ? this.clients[sprint.client_id].status : '--';
                        },
                        this.orderBy[1]
                    );
                case "payments.first":
                    return orderBy(
                        this.sprintsByClient,
                        "firstPayment",
                        this.orderBy[1]
                    );
                case "payments.last":
                    return orderBy(
                        this.sprintsByClient,
                        "lastPayment",
                        this.orderBy[1]
                    );
                case "invoiced":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return sprint.paid + sprint.unpaid;
                        },
                        this.orderBy[1]
                    );
                case "overdue":
                    return orderBy(
                        this.sprintsByClient,
                        "overdue",
                        this.orderBy[1]
                    );
                case "hours":
                    return orderBy(
                        this.sprintsByClient,
                        "actual.hours",
                        this.orderBy[1]
                    );
                case "cost":
                    return orderBy(
                        this.sprintsByClient,
                        "actual.cost",
                        this.orderBy[1]
                    );
                case "rate":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            if (sprint.actual.hours == 0) {
                                return 0;
                            }
                            return (sprint.paid + sprint.unpaid) / sprint.actual.hours
                        },
                        this.orderBy[1]
                    );
                case "weeks":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return this.numberOfWeeks(sprint)
                        },
                        this.orderBy[1]
                    );
                case "launch_date":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return sprint.client_id in this.clients ? this.clients[sprint.client_id].launch_date : '--';
                        },
                        this.orderBy[1]
                    );
                case "ttl":
                    return orderBy(
                        this.sprintsByClient,
                        sprint => {
                            return this.timeToLaunch(sprint)
                        },
                        this.orderBy[1]
                    );
            }
            return this.sprintsByClient;
        }
    },
    methods: {
        olderThan(date, days) {
            if (!days) {
                return moment.utc().diff(moment.utc(date, "YYYY-MM-DD HH:mm:ss"), "days")
            }
            return moment.utc().diff(moment.utc(date, "YYYY-MM-DD HH:mm:ss"), "days") > days
        },
        timeSinceFirstPayment(stats) {
            if (!(stats.client_id in this.clients)) {
                return 0;
            }
            const start = stats.firstPayment;
            if (!start) {
                return 0;
            }

            return moment.utc().diff(
                moment.utc(start, "YYYY-MM-DD HH:mm:ss"),
                "days"
            );
        },
        timeToLaunch(stats) {
            if (!(stats.client_id in this.clients)) {
                return 0;
            }
            const start = stats.firstPayment;
            const launch = this.clients[stats.client_id].launch_date;
            if (!start || !launch) {
                return 0;
            }

            return moment.utc(launch, "YYYY-MM-DD").diff(
                moment.utc(start, "YYYY-MM-DD HH:mm:ss"),
                "days"
            );
        },
        numberOfWeeks(stats) {
            if (!stats.firstPayment || !stats.lastPayment) {
                return 0;
            }
            return moment
                    .utc(stats.lastPayment, "YYYY-MM-DD HH:mm:ss")
                    .diff(
                        moment.utc(
                            stats.firstPayment,
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        "weeks"
                    ) + 1
        },
        translateStatus(status) {
            const statuses = {
                active: 'Active',
                canceled: 'Canceled',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            }
            return statuses[status] || status
        },
        statusColor(status) {
            const colors = {
                active: 'text-green-600',
                canceled: 'text-red-600',
                paused: 'text-purple-600',
                lost: 'text-orange-500',
                free: 'text-blue-600'
            }
            return colors[status] || 'text-gray-600'
        },
        money(num) {
            if (Number(num) >= 0) {
                let value = Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `$${value}`;
            } else {
                let value = Math.abs(Number(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `-$${value}`;
            }
        },
        number(num) {
            if (Number(num) >= 0) {
                let value = Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `${value}`;
            } else {
                let value = Math.abs(Number(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `-${value}`;
            }
        },
        datetime(dt) {
            let date = moment.utc(dt, "YYYY-MM-DD HH:mm:ss").local();
            if (
                date.format("YYYY") ==
                moment
                    .utc()
                    .local()
                    .format("YYYY")
            ) {
                return date.format("MMM Do, h:mma");
            }
            return date.format("MMM Do YYYY, h:mma");
        },
        date(dt) {
            let date = moment.utc(dt, "YYYY-MM-DD HH:mm:ss").local();
            if (
                date.format("YYYY") ==
                moment
                    .utc()
                    .local()
                    .format("YYYY")
            ) {
                return date.format("MMM Do");
            }
            return date.format("MMM Do YYYY");
        },
        order(key) {
            if (this.orderBy[0] == key) {
                this.orderBy = [key, this.orderBy[1] == "asc" ? "desc" : "asc"];
            } else {
                this.orderBy = [key, "asc"];
            }
        }
    },
    watch: {
    }
};
</script>

<style lang="postcss" scoped></style>
