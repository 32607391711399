<template>
    <Layout ref='layout' :auth='auth' container :show-header="!styled" :background-color="styled ? '#fefefe' : null">
        <template v-slot:title>
            <hgselect v-model="selectedClient" :options="otherClients" v-if="otherClients" class="text-black max-w-[500px]" />
            <span v-text="client.name + ' Analytics'" v-else></span>
        </template>
        <Head title="Client Analytics" />
        <template v-slot:actions>
            <div class="ml-auto flex gap-2">
                <btn @click="open(styledUrl)" class="whitespace-nowrap" :disabled="refreshing">
                    View Report
                </btn>
            </div>
        </template>
        <div
            v-if="pages.length > 1"
            class="flex items-center gap-5 mb-6"
        >
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select v-model="selectedPage" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <template v-for="(page, idx) in pages" :key="page.title">
                        <option :value="idx">{{ page.title }}</option>
                    </template>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="flex space-x-4" aria-label="Tabs">
                    <template v-for="(page, idx) in pages" :key="page.title">
                        <a
                            @click="selectedPage = idx"
                            :class="{'bg-blue-100 text-blue-700': selectedPage === idx, 'bg-gray-100 text-blue-500 hover:text-blue-700': selectedPage !== idx}"
                            class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                        >
                            {{ page.title }}
                        </a>
                    </template>
                </nav>
            </div>
            <div v-if="refreshing">
                <i class="fa fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="grid grid-cols-6 lg:grid-cols-12 gap-6 items-stretch bg-white p-4"
            :key="cardKey"
        >
            <component
                v-for="(card, idx) in pages[selectedPage].cards"
                :key="'card-' + idx + '-' + cardKey"
                class="flex flex-col"
                :class="{
                    ['col-span-' + card.size.columns]: card.size.columns,
                    ['row-span-' + card.size.rows]: card.size.rows,
                    ['col-start-' + card.size.colStart]: card.size.colStart,
                    ['row-start-' + card.size.rowStart]: card.size.rowStart,
                }"
                :style="{
                    'height': card.size.height ? `${card.size.height}px` : 'auto',
                    ...(card.meta.styles ? card.meta.styles : {})
                }"
                :is="'Throb' + card.class"
                :title="card.title"
                :data="card.data"
                :meta="card.meta"
                :settings="editableSettings"
                @updated="cardUpdated"
            />
        </div>
    </Layout>
</template>

<script>
import { nextTick } from 'vue'
import { Head } from '@inertiajs/vue3'
import Layout from "../Common/Layout.vue"
import btn from "../Common/btn.vue"
import auth from "../Common/Mixins/auth"
import ThrobAccordion from "../Throb/Accordion.vue"
import ThrobMaskable from "../Throb/Maskable.vue"
import ThrobTable from "../Throb/Table.vue"
import ThrobPie from "../Throb/Pie.vue"
import ThrobLine from "../Throb/Line.vue"
import ThrobHeader from "../Throb/Header.vue"
import ThrobBar from "../Throb/Bar.vue"
import ThrobImage from "../Throb/Image.vue"
import ThrobDatePicker from "../Throb/DatePicker.vue"
import ThrobStatistic from "../Throb/Statistic.vue"
import ThrobSentence from "../Throb/Sentence.vue"
import ThrobRow from "../Throb/Row.vue"
import Litepicker from 'litepicker'
import hgselect from "../Common/hgselect.vue"

export default {
    mixins: [auth],
    components: { Head, Layout, btn, ThrobMaskable, ThrobTable, ThrobAccordion, ThrobDatePicker, ThrobHeader, ThrobPie, ThrobBar, ThrobImage, ThrobRow, ThrobSentence, ThrobStatistic, ThrobLine, hgselect },
    props: {
        auth: Object,
        client: Object,
        otherClients: Object,
        pages: Array,
        from: String,
        to: String,
        months: Object,
        settings: Object,
        styled: Boolean
    },
    computed: {
        styledUrl () {
            let url = this.$route('throb.case-study', this.client.id)
            let params = {
                from: this.selectedMonth[0],
                to: this.selectedMonth[1],
                styled: true,
            }
            return url + '?' + new URLSearchParams(params).toString()
        },
        monthsWithoutLast() {
            return this.months.slice(0, -1)
        },
        customDateRangePlaceholder() {
            if (this.customDateRange.from && this.customDateRange.to) {
                return `Custom: ${this.customDateRange.from} - ${this.customDateRange.to}`
            }
            return 'Custom Date Range'
        },
    },
    data() {
        return {
            refreshing: false,
            cardKey: (new Date).getTime(),
            selectedPage: 0,
            selectedMonth: [this.from, this.to],
            selectedClient: this.client.id,
            editableSettings: {...this.settings}
        }
    },
    methods: {
        open(styledUrl) {
            window.open(styledUrl, '_blank')
        },
        clientChanged() {
            this.$inertia.visit(
                this.$route('throb.case-study', this.selectedClient)
            )
        },
        cardUpdated(data) {
            if (data && data.key && data.value) {
                if (data.key === 'dates') {
                    this.selectedMonth = data.value
                } else {
                    this.editableSettings[data.key] = data.value
                }
            }
            // this.$inertia.reload({
            //     data: {
            //         from: this.selectedMonth[0],
            //         to: this.selectedMonth[1]
            //     },
            //     only: ['pages'],
            //     preserveScroll: true,
            //     onFinish: () => {
            //         this.cardKey = (new Date).getTime()
            //     },
            // })
        },
        canCloseTimeSelector() {
            return !this.picker
        },
        setupLitePicker() {
            nextTick(() => {
                if (!this.picker) {
                    this.picker = new Litepicker({
                        element: this.$refs.litepicker,
                        singleMode: false,
                        numberOfColumns: 2,
                        numberOfMonths: 2,
                        setup: (picker) => {
                            picker.on('selected', (start, end) => {
                                this.customDateRange.from = start ? start.format('YYYY-MM-DD') : null
                                this.customDateRange.to = end ? end.format('YYYY-MM-DD') : null
                                this.dateRange = 'custom'
                                this.tearDownLitePicker()
                            });
                        }
                    })
                }
                if (this.customDateRange.from && this.customDateRange.to) {
                    this.picker.setDateRange(
                        this.customDateRange.from,
                        this.customDateRange.to
                    )
                }
            })
        },
        tearDownLitePicker() {
            if (this.picker) {
                this.picker.destroy()
                this.picker = null
            }
        },
        refreshPages() {
            this.$inertia.reload({
                data: {
                    from: this.selectedMonth[0],
                    to: this.selectedMonth[1],
                },
                only: ['pages'],
                preserveScroll: true,
                onFinish: () => {
                    this.cardKey = (new Date).getTime()
                },
            })
        }
    },
    watch: {
        'selectedClient': {
            deep: true,
            handler() {
                this.clientChanged();
            }
        },
        'selectedMonth': {
            deep: true,
            handler() {
                this.refreshPages();
            }
        },
        'customDateRange': {
            deep: true,
            handler() {
                this.refreshPages();
            }
        }
    }
}
</script>
